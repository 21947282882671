import { useNavigate } from 'react-router-dom';
import './SubMenu.css';
import { MenuItem } from '../../Data/NavBar.data';
import styled from 'styled-components';
import tw from 'twin.macro';

interface SubMenuProps {
    menu: MenuItem;
}

const SubMenu = (props: SubMenuProps) => {
    const { menu } = props;
    const navigate = useNavigate();

    return (
        <div className='group'>
            <SubMenuTitle
                is_link={menu.is_link ? true : false}
                onClick={
                    menu.is_link ? () => navigate(`/${menu.title.replace(/ /g, "-")}`) : () => {}
                }
            >
                {menu.title}
            </SubMenuTitle>
            {menu.submenu ? (
                <div className='absolute top-20 lowercase capitalize w-auto min-w-[11rem] z-50 font-sans hidden group-hover:block hover:block'>
                    <div className='w-full h-1 absolute bg-orange-500'></div>
                    <div className='bg-white w-full py-2 px-5 shadow-lg rounded-md'>
                        {menu.submenu.map((link) => (
                            <p className='text-sm text-gray-500 my-4 hover:text-cyan-500 hover:cursor-pointer duration-300'>
                                <span onClick={() => navigate(`/${link}`)}>
                                    {link}
                                </span>
                            </p>
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
        // <div className="signup-container">
        //     <div className="signupAd">
        //         <div className="headline">Resources</div>
        //     </div>
        //     <ul className="signup-list">
        //         <li className="first">TRACK your favorite TV shows</li>
        //         <li className="second">CHOOSE from 24,000 shows</li>
        //         <li className="third">EARN badges for watching</li>
        //         <li className="fourth">GET A FREE ACCOUNT</li>
        //     </ul>
        // </div>
    );
};

export default SubMenu;

const SubMenuTitle = styled.span<{ is_link: boolean }>`
    ${tw`py-7 px-3 inline-block hover:text-cyan-500 duration-300`}

    /* change cursor style on hover based on whether the item is a link */
    &:hover {
        cursor: ${(props) => (props.is_link ? 'pointer' : '')};
    }
`;

import styled from 'styled-components';
import tw from 'twin.macro';

export interface alumniProps {
    title: string;
    name: string;
    bgColor: string;
}

const AlumiContainer = styled.div`
    ${tw`my-2`};
`;

const AlumniTitle = styled.p`
    ${tw`text-gray-500 text-lg`}
`;

const AlumniName = styled.p`
    ${tw`text-2xl absolute left-[20%] top-[50%] whitespace-nowrap`};
`;

const AlumniBackground = styled.div`
    ${tw`w-[8vw] h-[4vh] relative`};
`;

const Alumni = (props: alumniProps) => {
    const { title, name, bgColor } = props;

    return (
        <AlumiContainer>
            <AlumniTitle>{title}</AlumniTitle>
            <AlumniBackground className={`${bgColor}`}>
                <AlumniName>{name}</AlumniName>
            </AlumniBackground>
        </AlumiContainer>
    );
};

export default Alumni;

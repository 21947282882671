import event_background from '../../assets/events/event_background.jpg';
import styled from 'styled-components';
import tw from 'twin.macro';

const EventCard = () => {
    return (
        <EventCardContainer className='group'>
            <EventCardPhotoContainer>
                <EventCardPhoto
                    src={event_background}
                    alt='Sunset in the mountains'
                />
            </EventCardPhotoContainer>
            <EventCardDetailContainer>
                <EventCardDateContainer>
                    <EventCardMonth>NOV</EventCardMonth>
                    <EventCardDay>07</EventCardDay>
                </EventCardDateContainer>
                <EventCardType>workshop</EventCardType>
                <EventCardTitle>
                    Lavender Upcoming Event title #1
                </EventCardTitle>
                <EventCardTime>10:00 AM PT</EventCardTime>
            </EventCardDetailContainer>
        </EventCardContainer>
    );
};

export default EventCard;


const EventCardContainer = styled.div`
    ${tw`rounded shadow-lg flex flex-col w-[18.5rem] h-[32rem] hover:shadow-xl duration-300 bg-white`}
`;

const EventCardPhotoContainer = styled.div`
    ${tw`w-full h-[18rem] overflow-hidden`}
`;

const EventCardPhoto = styled.img`
    ${tw`h-full w-full object-cover group-hover:scale-[1.05] duration-300`}
`;

const EventCardDetailContainer = styled.div`
    ${tw`px-4 py-4 pt-12 relative`};
`;

const EventCardDateContainer = styled.div`
    ${tw`bg-blue-900 w-[4.6rem] h-[4.6rem] text-white flex flex-col justify-center items-center absolute top-[-2.3rem] left-[1.5rem] shadow-lg`};
`;

const EventCardMonth = styled.div``;

const EventCardDay = styled.div`
    ${tw`font-bold text-xl`};
`;

const EventCardType = styled.div`
    ${tw`text-lg uppercase text-rose-800`};
`;

const EventCardTime = styled.div`
    ${tw`text-lg`};
`;

const EventCardTitle = styled.div`
    ${tw`font-bold text-xl mb-2 group-hover:text-cyan-500 hover:cursor-pointer duration-300`};
`;
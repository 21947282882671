import slider1 from "../assets/slider/slider_img_1.jpg"
import slider2 from "../assets/slider/slider_img_2.jpg"
import slider3 from "../assets/slider/slider_img_3.jpg"
import background from "../assets/department/department-background.jpg"

export const sliderData = [
    {
        image: slider1,
        heading: "四月·追光",
        discription:
            "人间四月芳菲尽，山寺桃花始盛开。初春四月，我们成功举办了2021海内外多元青年论坛。来自近十个不同国家和地区的上百名小伙伴聚在一起，共话未来，用真诚、勇气、思考为新的十年拉开帷幕...",
    },
    {
        image: slider2,
        heading: "希望你看见大千世界，包容同与不同",
        discription:
            "这是你的小小树洞。只要你想被倾听，我们就一直陪伴。如果现实无法把暗淡抛掷，追光学社愿意成为那道光，陪你一起穿透重重迷雾...",
    },
    {
        image: slider3,
        heading: "酷玩人生｜Stay Queer 涂鸦墙圆满收官",
        discription: "像是某个清晨忽然发现树叶变成红色一样，我们在 Sproul Plaza 的五天时光也被膨胀的色彩填满了。缤纷的颜色涂在手上，向世界招摇独一无二的自己..."
    }
]

export const departmentData = [
    {
        title: "Social",
        image: background,
        description: "Organize internal events to create bondings between LAB members; Establish a safe space for open discussion"
    },
    {
        title: "Operations",
        image: background,
        description: "Manage meeting minutes and logistics; Organize membership database"
    },
    {
        title: "Marketing",
        image: background,
        description: "Create advertising graphics to promote LAB’s internal and external events; Develop marketing materials for fundraising events"
    },
    {
        title: "Professional Development",
        image: background,
        description: "Build and maintain LAB’s website; Establish career and scholarship opportunities for LGBTQ+ students; Build alumni connections"
    },
    {
        title: "Publicity",
        image: background,
        description: "Publish LGBT-related articles to spread awareness of Asian LGBTQ+ communities; Advocate for social change; Publish monthly newsletters on LGBTQ+ news"
    },
    {
        title: "Programming",
        image: background,
        description: "Organize external events to raise awareness of Asian LGBTQ+ communities and celebrate LGBTQ+ identities"
    },
    {
        title: "Outreach",
        image: background,
        description: "Establish connections with Chinese LGBTQ+ organizations and AAPI LGBTQ+ organizations; Seek collaboration opportunities"
    },
    {
        title: "Finance",
        image: background,
        description: "Organize fundraising events to support LAB and local queer communities"
    },
]

export const blogSlideData = [
    {
        title: "逆流而上，向光而行｜2022海内外多元青年峰会报名开启",
        author: "Lavender Bear",
        date: "October 15, 2022",
        description:
            "人们常说，所爱隔山海，山海皆可平。当爱与身份认同被贴上“是”与“非”，有一个群体，则需翻越陡峭的山峰、逆向湍急的水流，方能追逐心之所爱。",
        background: "",
        link: "https://mp.weixin.qq.com/s/3uJDkN5qO2eEnj6dh7ZgDw",
    },
    {
        title: "共赴彩虹之约 | 峰会嘉宾揭晓",
        author: "Lavender Bear",
        date: "October 16, 2022",
        description:
            "随着时代的变迁和民众思想的改变，性多元群体依然在逆水行舟。追光学社一直致力于打造一个爱与包容的环境并给这个特殊群体一个表达立场的安全的平台。",
        background: "",
        link: "https://mp.weixin.qq.com/s/kWegvihSlswrBya9Ae4BFg",
    },
    {
        title: "静候黎明｜2022海内外多元青年峰会内容简介",
        author: "Lavender Bear",
        date: "October 17, 2022",
        description:
            "这个春天，追光 Lavender 再次邀请海内外具有影响力的 LGBTQ+ 组织及个人，举办2022海内外多元青年峰会，为中国性多元群体创造一个畅所欲言、自由交流的平台。",
        background: "",
        link: "https://mp.weixin.qq.com/s/qaJ3WwikLrHg5uhG5EsQjQ",
    },
    {
        title: "赴一场属于彩虹的宴会 | 2021海内外多元青年峰会报名开启",
        author: "Lavender Bear",
        date: "October 18, 2022",
        description:
            "几天前，一则关于一位16岁男生被性侵后遭退学的消息出现在微博，很快，相关的求助推文也刷屏了朋友圈。",
        background: "",
        link: "https://mp.weixin.qq.com/s/QASUnrrvh-kFXh8jHPb_gA",
    },
    {
        title: "峰会主题演讲 | 去病理化20年的成长与改变",
        author: "Lavender Bear",
        date: "October 17, 2022",
        description:
            "同性恋是病吗? 这可能是大多数人谈到同性恋时脑海里闪出的第一个问题。纵观历史，这也是世界范围内性少数群体争取权益的锚点：向不一样的“大多数人”证明，同性恋不是病、非二元性别认同不是病......",
        background: "",
        link: "https://mp.weixin.qq.com/s/dzMbOey1vTySigTfa7t_3A",
    },
    {
        title: "【峰会介绍】主题研讨会 | 自我发展与探索",
        author: "Lavender Bear",
        date: "October 18, 2022",
        description:
            "在寻找真实自我的道路上，我们往往经历了比别人更多的自我怀疑和挫折。我对ta到底是哪种喜欢？我到底喜欢的是什么性别的你？我是男孩子/女孩子/非二元性别吗？",
        background: "",
        link: "https://mp.weixin.qq.com/s/mEphTvT1czzhgAHJmbxXvQ",
    },
]

export const blogArchiveData = [
    "峰会系列",
    "一周伙伴",
    "科普推文",
    "合作活动",
    "追光时事",
    "招新推文",
    "征稿活动",
    "追光故事会",
    "节日祝福",
]

import { useState, useEffect } from 'react'
import "./Slider.scss"
import { sliderData } from '../../Data/Data'


const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const slideLength = sliderData.length

    const autoScroll = true;
    let slideInterval:any;
    let intervalTime = 5000;

    const nextSlide = () => {
        setCurrentSlide((currentSlide + 1) % 3)
    }

    const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime)
    }

    useEffect(() => {
        setCurrentSlide(0)
    }, []) 

    useEffect(() => {
        if (autoScroll) {
            auto()
        }
        return () => clearInterval(slideInterval)
    }, [currentSlide])

    return (
        <div className="slider bg-gradient-to-b from-white to-[#E6E6FA]">
            {sliderData.map((slide, index) => {
                return (
                    <div className={index === currentSlide ? "slide" : ""} key={`slide-${index}`}>
                        {currentSlide === index && (
                            <div className='container flex-col md:flex-row items-center md:justify-center'>
                                <div className='content md:w-[41%] md:top-[25vh] top-[60vw] left-[3rem]'>
                                    <div className='w-full'>
                                        <h1 className="md:text-4xl text-[5vw] font-bold mt-0 mb-6">{sliderData[(index + 1) % 3].heading}</h1>
                                        <p className='md:text-xl text-[3vw] mb-6'>{sliderData[(index + 1) % 3].discription}</p>
                                        <div className="flex space-x-2">
                                            <button
                                                type="button"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                                onClick={() => setCurrentSlide((currentSlide + 1) % 3)}
                                            >Read More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='contentWithAnimation md:w-[41%] md:top-[25vh] top-[60vw] left-[3rem]'>
                                    <div className='w-full'>
                                        <h1 className="md:text-4xl text-[5vw] font-bold mt-0 mb-6">{slide.heading}</h1>
                                        <p className='md:text-xl text-[3vw] mb-6'>{slide.discription}</p>
                                        <div className="flex space-x-2">
                                            <button
                                                type="button"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                                onClick={() => setCurrentSlide((currentSlide + 1) % 3)}
                                            >Read More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="slideImgContainer w-[80%] md:w-[41%] md:right-[3rem] flex justify-center md:items-center">
                                    <img className={'slideImg object-cover rounded-2xl w-full md:h-[52vh] h-[50vw] shadow-xl'} src={sliderData[(index + 1) % 3].image} alt="slide"></img>
                                    <img className={'slideImgWithAnimation object-cover w-full md:h-[52vh] h-[50vw] rounded-2xl shadow-xl'} src={slide.image} alt="slide"></img>
                                </div>
                            </div>
                        )}
                    </div>
                )
            })}
            {/* <div className='flex gap-3 absolute bottom-0'>
                <div className="rounded-full w-4 p-2 border-2 border-lime-600"></div>
                <div className="rounded-full bg-cyan-300 w-4 p-2 border-2 border-cyan-300"></div>
                <div className="rounded-full w-4 p-2 border-2 border-violet-600"></div>
                <div className="rounded-full  w-4 p-2 border-2 border-orange-400"></div>
            </div> */}
        </div>
    )
}

export default Slider
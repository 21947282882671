import styled from 'styled-components';
import tw from 'twin.macro';
import { useEffect, useState } from 'react';
import banner_background from '../assets/blogsPage/banner_background.jpg';

// purposefully condensed all the files into this one file

const JoinUs = () => {
    const [dimensions, set_dimensions] = useState({
        height: 1000,
        width: 1000,
    });

    useEffect(() => {
        set_dimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });

        const handleResize = () => {
            set_dimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <div className='w-full h-[20rem] relative'>
                <img
                    className='h-full w-full object-cover brightness-[0.7] absolute'
                    src={banner_background}
                    alt='banner background'
                />
                <div className='absolute bottom-[30%] left-[10%] text-white'>
                    <h1 className='text-4xl font-semibold'>Join Us</h1>
                    <h3 className='text-2xl pt-3 font-medium'>Home/join</h3>
                </div>
            </div>

            {/* <JoinUsTitle>Join Us</JoinUsTitle> */}
            <div className='w-full flex flex-col justify-center items-center'>
                <JoinOptionsContainer>
                    <div>
                        <JoinOptionTitle>🌈 Become a Member</JoinOptionTitle>
                        <div className='text-lg'>
                            To be a member of a student-organized LGBTQ+ club,
                            it's important to respect everyone's identity and
                            pronouns, create a safe and inclusive space, educate
                            yourself and others, attend meetings and events,
                            volunteer and get involved, be an ally, and maintain
                            confidentiality when necessary. By following these
                            guidelines, you can help build a positive and
                            supportive community for all members of the club.
                        </div>
                        <JoinButtonInactive type='button'>Join Today</JoinButtonInactive>
                        <i className='ml-3 mt-2 text-zinc-500'>
                            * link under construction
                        </i>
                    </div>
                    <div>
                        <JoinOptionTitle>🏳️‍🌈 Become an Officer</JoinOptionTitle>
                        <div className='text-lg'>
                            To be an officer of a student-organized LGBTQ+ club,
                            it's important to lead by example, communicate
                            effectively, plan and organize events, listen to
                            feedback, manage conflicts, maintain
                            confidentiality, and promote education and advocacy.
                            As an officer, your role is to create a safe and
                            inclusive environment for all members, and to use
                            your position to promote awareness and education
                            about LGBTQ+ issues. By following these guidelines,
                            you can help build a stronger and more supportive
                            community for everyone in the club.
                        </div>
                        <JoinButtonInactive type='button'>Join Our Team</JoinButtonInactive>
                        <i className='ml-3 mt-2 text-zinc-500'>
                            * application opens every semester
                        </i>
                    </div>
                </JoinOptionsContainer>
            </div>
        </div>
    );
};

export default JoinUs;

const JoinButton = styled.button`
    ${tw`block px-8 py-2.5 text-white font-medium text-xl font-bold rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out mt-4`};
`;

const JoinButtonInactive = styled(JoinButton)`
    ${tw`cursor-not-allowed bg-zinc-600 hover:bg-zinc-700 focus:bg-zinc-700 active:bg-zinc-800`};
`;

const JoinButtonActive = styled(JoinButton)`
    ${tw`bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-800`}
`

const range = ({ start, end }: { start?: number; end: number }) =>
    Array.from(
        { length: end - (start ? start : 0) },
        (v, k) => k + (start ? start : 0)
    );

const JoinOptionsContainer = styled.div`
    ${tw`mt-16`};
    display: grid;
    width: 80%;
    grid-template-columns: auto auto;
    column-gap: 5%;
    ${tw`mb-10`}
`;

const JoinOptionTitle = styled.h1`
    ${tw`text-[1.7rem] font-bold mt-0 mb-4 pb-2 text-zinc-800`};
    border-bottom: 1px;
    border-style: solid;
    border-color: #99999999;
`;

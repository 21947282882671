import blog from "../../assets/blogs/blog.jpg"
import card_background from "../../assets/blogs/card_background.jpg"
import { FaUserAlt } from "react-icons/fa"
import { AiOutlineUser } from "react-icons/ai"
import { FaLongArrowAltRight } from "react-icons/fa"
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { AiTwotoneCalendar } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'

export interface CardInfo {
  title: string;
  author: string;
  date: string;
  description: string;
  background?: string;
  link: string;
}

const Card = (cardInfo: CardInfo) => {

  const {title, author, date, description, background, link} = cardInfo;

  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-[22.5rem] h-[28rem]">
      <div className='w-full h-[13rem]'>
        <img className="h-full w-full object-cover" src={background ? background : card_background} alt="Sunset in the mountains" />
      </div>
      <div className="px-1 py-4">
        <a className="font-bold text-xl mb-2 hover:text-cyan-500 hover:cursor-pointer duration-300 line-clamp-2"
           href={link}>
          {title}
        </a>
        <div className='flex justify-between mb-3'>
          <div className='flex justify-start w-[50%] items-center gap-2'>
            <FaUserAlt color='darkblue'></FaUserAlt>
            <p>{author}</p>
          </div>
          <div className='flex justify-start w-[50%] items-center gap-2'>
            <AiTwotoneCalendar color='darkblue' size={20}></AiTwotoneCalendar>
            <p>{date}</p>
          </div>
        </div>
        <p className="text-gray-500 text-base mb-3 line-clamp-3">{description}</p>
        <a href={link} className='flex items-center hover:text-cyan-500 hover:cursor-pointer duration-300'>
          <p className='mr-1.5'>Read More</p>
          <HiOutlineArrowNarrowRight size={20}></HiOutlineArrowNarrowRight>
        </a>
      </div>
    </div>
  )
}

export default Card
import React, { useState } from 'react';
import Logo from '../../assets/lavender_logo@1x.png';
import { FiMenu } from 'react-icons/fi';
import { FiFacebook } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';
import { RiWechatLine } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import SubMenu from '../SubMenu/SubMenu';
import './Navbar.scss';
import {
    MobileNavBarItem,
    MobileNavBarItemsContainer,
    NavBarContainer,
    NavBarItemsContainer,
    NavLogoContainer,
    SocialMediaButton,
    SocialMediaButtonsContainer,
} from './Navbar.styled';
import { MenuItems } from '../../Data/NavBar.data';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [active, setActive] = useState(true);

    const navigate = useNavigate();

    const navStatus = () => {
        if (window.scrollY >= 96) {
            setActive(false);
        } else {
            setActive(true);
        }
    };

    window.addEventListener('scroll', navStatus);

    return (
        <nav
            className={`bg-white h-24 shadow-lg w-full ${
                !active && !menuOpen && 'inActiveBar'
            }`}
        >
            <NavBarContainer>
                <NavLogoContainer>
                    <img
                        src={Logo}
                        alt='logo'
                        className='h-[85%] mr-2.5 hover:cursor-pointer'
                        onClick={() => navigate('/')}
                    />
                    {/* <div className='flex items-center md:cursor-pointer h-full z-50 md:w-auto'>
            <img src={Logo} alt="logo" className="h-full mr-2.5"/>
            <span className="md:text-2xl text-2xl whitespace-nowrap">Lavender Alliance</span>
          </div> */}
                    <div
                        className='text-3xl md:hidden'
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        {menuOpen ? <IoMdClose /> : <FiMenu />}
                    </div>
                </NavLogoContainer>
                <NavBarItemsContainer>
                    {MenuItems.map((menu, index) => (
                        <li key={`menu-${index}`}>
                            <SubMenu
                                menu={menu}
                            ></SubMenu>
                        </li>
                    ))}
                    <SocialMediaButtonsContainer>
                        <SocialMediaButton hoverColor='blue'>
                            <FiFacebook />
                        </SocialMediaButton>
                        <SocialMediaButton hoverColor='purple'>
                            <FaInstagram />
                        </SocialMediaButton>
                        <SocialMediaButton hoverColor='purple'>
                            <RiWechatLine />
                        </SocialMediaButton>
                    </SocialMediaButtonsContainer>
                </NavBarItemsContainer>
                {/* Mobile Dev */}
                <MobileNavBarItemsContainer
                    className={`${menuOpen ? 'z-10 left-0' : 'left-[-100%]'}`}
                >
                    <li key={`menu-mobile-about`}>
                        <MobileNavBarItem>About</MobileNavBarItem>
                    </li>
                    <li key={`menu-mobile-programs`}>
                        <MobileNavBarItem>Programs</MobileNavBarItem>
                    </li>
                    <li key={`menu-mobile-blog`}>
                        <MobileNavBarItem>Blog</MobileNavBarItem>
                    </li>
                    <li key={`menu-mobile-contact`}>
                        <MobileNavBarItem>Contact</MobileNavBarItem>
                    </li>
                </MobileNavBarItemsContainer>
            </NavBarContainer>
        </nav>
    );
};

export default Navbar;

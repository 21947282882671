import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../sections/Footer/Footer';
import Navbar from '../Navbar/Navbar';

const NavigationStructure = () => {
    return (
        <Fragment>
            <Navbar />
            <Outlet />
            <Footer />
        </Fragment>
    );
};

export default NavigationStructure;

import React from 'react'
import { useNavigate } from 'react-router-dom';
import EventCard from '../../components/EventCard/EventCard'
import { blogSlideData } from '../../Data/Data'

const Events = () => {

  const navigate = useNavigate();
  
  return (
    <div className='flex flex-col items-center justify-center w-full bg-amber-50 py-9'>
        <h1 className="text-[2.7rem] font-bold mt-0 mb-10 text-zinc-800">Upcoming Events</h1>
        <div className='w-full flex flex-wrap justify-center gap-11 mb-8'>
          <EventCard />
          <EventCard />
          <EventCard />
          <EventCard />
          {/* {blogSlideData.map((data, index) => index <= 2 && (
            <Blog key={`blog-slide-${index}`} title={data.title} author={data.author} date={data.date} description={data.description}/>
          ))} */}
        </div>
        <button type="button" 
                className="focus:outline-none text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 focus:ring-purple-300 rounded-lg text-xl px-20 py-3 mb-2"
                onClick={() => navigate('/events')}>
          More events
          </button>
    </div>
  )
}

export default Events
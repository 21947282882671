import styled from 'styled-components';
import tw from 'twin.macro';
import bg_img_1 from '../../assets/AboutUs/AboutUs_bg_img_1.jpg';
import bg_img_2 from '../../assets/AboutUs/AboutUs_bg_img_2.jpg';

// @audit fix the design
const AboutUs = () => {
    return (
        <div className='w-full h-auto bg-gradient-to-b from-[#E6E6FA] to-amber-50 flex md:flex-row flex-col justify-between items-center p-10'>
            <div className='w-full md:w-[48%] h-[70vh] relative'>
                <div className='absolute top-0 left-0 h-[40vh] md:h-[55vh] w-[80%]'>
                    <img src={bg_img_1} className='object-cover'></img>
                </div>
                <div className='absolute top-[30vh] right-0 h-[25vh] md:h-[35vh] w-[60%]'>
                    <img src={bg_img_2} className='object-cover'></img>
                </div>
            </div>
            <div className='w-full md:w-[48%] h-[40vh] md:h-[70vh] relative'>
                <p className='text-[3.6vw] font-semibold'>我们是谁</p>
                <div className='text-[1.3vw] mt-3'>
                    <p>你好,</p>
                    <p>我们是追光学社,</p>
                    <p>Lavender Alliance at Berkeley,</p>
                    <p>在诺大的华人社群里, 性别和性取向好像是一个禁忌,</p>
                    <p>它们真实存在着, 去不能言说</p>
                    <p>性少数群体真实地存在着, 却无法发声.</p>
                </div>
                <div className='text-[1.3vw] mt-3'>
                    <p>我们厌倦了沉寂, 受够了沉默.</p>
                    <p>
                        我们要做自己的平台,
                        为伯克利华人文化圈中的性少数群体创造一个属于他们的空间.
                    </p>
                    <p>在这里, 文化与成长背景会成为骄傲, 而非负担</p>
                    <p>
                        在这里, 你的情感可以倾诉, 故事会被倾听,
                        而不在有忽视、沉默
                    </p>
                </div>
                <div className='text-[1.3vw] mt-3'>
                    <p>
                        终于, 经Office of ASUC Senator Rex Zhang牵头,
                        我们联合有共同理想的社群成员,
                        在2020年成立了追光学社-Lavender Alliance At Berkeley.
                    </p>
                </div>
                <div className='text-[1.3vw] mt-3'>
                    <p>
                        我们想要成为你的safe space, 想要争取一个更好的社群,
                        想要你们都成为更好的自己.
                    </p>
                </div>
            </div>
        </div>
        // <AboutUsSection>
        //     <AboutUsTitle>About Us</AboutUsTitle>
        //     <AboutUsContent>
        //         We are the Lavender Alliance at Berkeley, the one and only
        //         LGBTQIA+ student organization dedicated to Chinese American and
        //         Chinese International Students. As the name alliance entails, we
        //         welcome students of all gender identities and sexual
        //         orientations. Together, we will build a safer community, strive
        //         for equity, and become better versions of ourselves.
        //     </AboutUsContent>
        // </AboutUsSection>
    );
};

export default AboutUs;

const AboutUsSection = styled.div`
    padding: 10vmin;
    display: block;
    background-color: #8c439928;
    place-items: center center;
    text-align: center;
`;

const AboutUsTitle = styled.h1`
    ${tw`text-[2rem] font-bold mt-0 mb-10 text-zinc-800`}
    place-self: center;
`;

const AboutUsContent = styled.div`
    ${tw`text-xl mb-6`}
`;

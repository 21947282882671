export interface MenuItem {
    title: string;
    is_link?: boolean;
    submenu?: string[];
}

export const MenuItems: MenuItem[] = [
    {
        title: 'About',
        submenu: ['Alumni', 'Department'],
    },
    {
        title: 'Blogs',
        is_link: true,
    },
    {
        title: 'Events',
        is_link: true,
    },
    // {
    //     title: 'Resources',
    //     submenu: ['Academic', 'Career', 'Mental Health', 'Physical Health'],
    // },
    {
        title: 'Join Us',
        is_link: true,
    },
];

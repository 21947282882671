import { Fragment } from 'react';
import Slider from '../components/Slider/Slider';
import AboutUs from '../sections/AboutUs/AboutUs.section';
import Events from '../sections/Events/Events';

const HomePage = () => {
    return (
        <Fragment>
            <Slider />
            <AboutUs />
            <Events />
        </Fragment>
    );
};

export default HomePage;

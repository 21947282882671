import React from 'react'

const Footer = () => {
  return (
    <div className='bg-[#0f4c9f] w-full md:p-14 p-8'>
        <div className='flex md:flex-row flex-wrap md:gap-y-2 gap-y-8 justify-around text-white'>
            <div className='flex flex-col gap-4 md:w-[25%]'>
                <p className='text-2xl'>Contact Us</p>
                <p className='text-lg'>University of California, Berkeley</p>
                <p className='text-lg'>lavenderallianceberkeley@gmail.com</p>
            </div>
            <div className='flex flex-col gap-4 md:w-[25%]'>
                <p className='text-2xl'>Site Map</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>About Us</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>LAB Leadership</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Resources</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Join Us</p>
            </div>
            <div className='flex flex-col gap-4 md:w-[25%]'>
                <p className='text-2xl'>Social Media</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Wechat</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Instagram</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Facebook</p>
                <p className='text-lg hover:text-cyan-500 hover:cursor-pointer duration-300'>Email</p>
            </div>
            <div className='flex flex-col gap-4 md:w-[25%]'>
                <p className='text-2xl'>Student Group Disclaimer</p>
                <p className='text-lg'>We are a student group in Univeristy of California, Berkeley. We welcome all groups to join us and contribute to the community together</p>
            </div>
        </div>
    </div>
  )
}

export default Footer
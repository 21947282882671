import { useRef, useState } from 'react';
import banner_background from '../assets/blogsPage/banner_background.jpg';
import card_background from '../assets/blogs/card_background.jpg';
import blog_background from '../assets/blogs/blog.jpg';
import { blogArchiveData, blogSlideData } from '../Data/Data';
import Blog from '../components/Blog/Blog';
import Pagination from '../components/Pagination/Pagination';

const BlogsPage = () => {
    const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
    const [currentPage, setCurrentPage] = useState(1);
    const [allowTurningPage, setAllowTurningPage] = useState(true);
    const [total, setTotal] = useState(5);
    const pageClick = useRef(null);

    return (
        <div>
            <div className='w-full h-[20rem] relative'>
                <img
                    className='h-full w-full object-cover brightness-[0.7] absolute'
                    src={banner_background}
                    alt='banner background'
                />
                <div className='absolute bottom-[30%] left-[10%] text-white'>
                    <h1 className='text-4xl font-semibold'>Our Posts</h1>
                    <h3 className='text-2xl pt-3 font-medium'>Home/Posts</h3>
                </div>
            </div>
            <div className='h-auto w-full flex justify-center pt-16 gap-8 mb-10'>
                <div className='h-fit w-[28%] bg-[#f9fafc] rounded p-8'>
                    <div className='w-full'>
                        <div className='py-2 text-xl font-semibold border-b-2 border-blue-300 inline-block mb-5'>
                            Search
                        </div>
                        <form>
                            <label className='mb-2 text-sm font-medium text-gray-900 sr-only'>
                                Search
                            </label>
                            <div className='relative'>
                                <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                                    <svg
                                        aria-hidden='true'
                                        className='w-5 h-5 text-gray-500'
                                        fill='none'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            stroke-linecap='round'
                                            stroke-linejoin='round'
                                            stroke-width='2'
                                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type='search'
                                    id='default-search'
                                    className='block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    placeholder='Enter keywords'
                                    required
                                />
                            </div>
                        </form>
                    </div>
                    <div className='w-full mt-10'>
                        <div className='py-2 text-xl font-semibold border-b-2 border-blue-300 inline-block mb-5'>
                            Archives
                        </div>
                        {blogArchiveData.map((date, index) => (
                            <div
                                key={`blog-archive-${index}`}
                                className='py-2 text-lg border-b-[1.6px] border-gray-200 text-[#777777] mb-3 hover:text-cyan-500 hover:cursor-pointer duration-300'
                            >
                                {date}
                            </div>
                        ))}
                    </div>
                    <div className='w-full mt-10'>
                        <div className='py-2 text-xl font-semibold border-b-2 border-blue-300 inline-block mb-5'>
                            Recent Posts
                        </div>
                        {blogSlideData.map(
                            (data, index) =>
                                index <= 2 && (
                                    <div className='w-full h-[11vh] flex gap-5 mb-5'>
                                        <div className='w-[6rem] h-full'>
                                            <img
                                                className='h-full w-full object-cover'
                                                src={card_background}
                                                alt=''
                                            />
                                        </div>
                                        <div className='flex flex-col w-[12rem] justify-between'>
                                            <p className='text-xl hover:text-cyan-500 hover:cursor-pointer duration-300 line-clamp-2'>
                                                {data.title}
                                            </p>
                                            <p className='text-[#777777]'>
                                                {data.date}
                                            </p>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </div>
                <div className='h-auto w-[54%] flex flex-wrap justify-between gap-y-5'>
                    {blogSlideData.map((data, index) => (
                        <Blog
                            key={`blog-slide-${index}`}
                            title={data.title}
                            author={data.author}
                            date={data.date}
                            description={data.description}
                            background={blog_background}
                            link={data.link}
                        />
                    ))}
                    {/* <Pagination
            total={total}
            pageList={pageList}
            pageClick={pageClick}
            currentPage={currentPage}
            setPageList={setPageList}
            setCurrentPage={setCurrentPage}
            allowTurningPage={allowTurningPage}
            setAllowTurningPage={setAllowTurningPage}
          /> */}
                </div>
            </div>
        </div>
    );
};

export default BlogsPage;

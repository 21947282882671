import React from 'react'
import Alumni from '../components/Alumni/Alumni'
import slider1 from "../assets/slider/slider1.jpg"

const AlumniPage = () => {
  return (
    <div>
        <div className='w-full h-full gap-20 flex px-4 py-16 flex-col md:flex-row items-center justify-center'>
          <div className='md:w-[41%] left-[5rem]'>
            <div className='w-full'>
              <h1 className="text-4xl font-bold mt-0 mb-6">LAB Founders & Past Leadership</h1>
              <p className='text-xl mb-6'>Each year, we are delighted to welcome new members to join our student organization. However, as time goes by, ......</p>
            </div>
          </div>
          <div className="w-[80%] md:w-[41%] md:right-[5rem] flex justify-center items-center">
              <img className={'rounded-2xl shadow-xl'} src={slider1} alt="slide"></img>
          </div>
        </div>
        <div className='w-full py-8 px-20'>
          <p className='text-3xl font-bold mb-8'>Founders of the Lavender Alliance @ Berkeley</p>
          <Alumni title={"ASUC Senator 2020-2021"} name={"Rex Zhang"} bgColor={"bg-[#e7afb0]"}/>
        </div>
        <div className='w-full py-8 px-20'>
          <p className='text-3xl font-bold mb-8'>Leaders in Office 2020-2021</p>
          <Alumni title={"PRESIDENT"} name={"Rex Zhang"} bgColor={"bg-[#f0dcc8]"}/>
          <div className='flex gap-16 my-8'>
            <Alumni title={"EXTERNAL VICE PRESIDENT"} name={"Stanley Wen"} bgColor={"bg-[#fff6e1]"}/>
          </div>
          <div className='flex gap-16 my-8'>
            <Alumni title={"PROGRAM CHAIR"} name={"Isabel Wang"} bgColor={"bg-[#fff6e1]"}/>
            <Alumni title={"OUTREACH CHAIR"} name={"Eddie Guo"} bgColor={"bg-[#fff6e1]"}/>
            <Alumni title={"MARKETING CHAIR"} name={"Angela Zhou"} bgColor={"bg-[#fff6e1]"}/>
            <Alumni title={"POLICY CHAIR"} name={"Yuning Xue"} bgColor={"bg-[#fff6e1]"}/>
          </div>
          <div className='flex gap-16 my-8'>
            <Alumni title={"INTERNAL VICE PRESIDENT"} name={"Stanley Wen"} bgColor={"bg-[#c3e0e0]"}/>
          </div>
          <div className='flex gap-16 my-8'>
            <Alumni title={"PROGRAM CHAIR"} name={"Isabel Wang"} bgColor={"bg-[#c3e0e0]"}/>
            <Alumni title={"OUTREACH CHAIR"} name={"Eddie Guo"} bgColor={"bg-[#c3e0e0]"}/>
            <Alumni title={"MARKETING CHAIR"} name={"Angela Zhou"} bgColor={"bg-[#c3e0e0]"}/>
            <Alumni title={"POLICY CHAIR"} name={"Yuning Xue"} bgColor={"bg-[#c3e0e0]"}/>
          </div>
        </div>
        <div className='w-full py-8 px-20'>
          <p className='text-3xl font-bold mb-8'>Leaders in Office 2021-2022</p>
          <Alumni title={"PRESIDENT"} name={"Angela Zhou"} bgColor={"bg-[#f0dcc8]"}/>
          <div className='flex gap-16 my-8'>
            <Alumni title={"EXTERNAL VICE PRESIDENT"} name={"Catherine Bao"} bgColor={"bg-[#fff6e1]"}/>
          </div>
          <div className='flex gap-16 my-8'>
            <div className='flex flex-col gap-8'>
              <Alumni title={"PROGRAM CHAIR"} name={"Isabel Wang"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"PROGRAM VICE CHAIR"} name={"Zoey Zuo"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"PROGRAM VICE CHAIR"} name={"Kathy Liang"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"PROGRAM VICE CHAIR"} name={"Vivian Guo"} bgColor={"bg-[#fff6e1]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"OUTREACH CHAIR"} name={"Grey Xu"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"OUTREACH VICE CHAIR"} name={"Tony Kwok"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"OUTREACH VICE CHAIR"} name={"Cleo Lu"} bgColor={"bg-[#fff6e1]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"MARKETING CHAIR"} name={"Agens Lin"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"MARKETING VICE CHAIR"} name={"Jane Wang"} bgColor={"bg-[#fff6e1]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"POLICY CHAIR"} name={"Felicity Feng"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"POLICY CO-CHAIR"} name={"Caroline Luo"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"POLICY CO-CHAIR"} name={"Julie Chen"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"POLICY CO-CHAIR"} name={"Harrington Fan"} bgColor={"bg-[#fff6e1]"}/>
              <Alumni title={"POLICY CO-CHAIR"} name={"Lichuan Xu"} bgColor={"bg-[#fff6e1]"}/>
            </div>
          </div>
          <div className='flex gap-16 my-8'>
            <Alumni title={"INTERNAL VICE PRESIDENT"} name={"Sophie Xue"} bgColor={"bg-[#c3e0e0]"}/>
          </div>
          <div className='flex gap-16 my-8'>
            <div className='flex flex-col gap-8'>
              <Alumni title={"PROGRAM CHAIR"} name={"Jinyun"} bgColor={"bg-[#c3e0e0]"}/>
              <Alumni title={"PROGRAM VICE CHAIR"} name={"Ricky Luo"} bgColor={"bg-[#c3e0e0]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"OUTREACH CHAIR"} name={"Mila Zou"} bgColor={"bg-[#c3e0e0]"}/>
              <Alumni title={"OUTREACH VICE CHAIR"} name={"Xiaowen Chen"} bgColor={"bg-[#c3e0e0]"}/>
              <Alumni title={"OUTREACH VICE CHAIR"} name={"Doris Liu"} bgColor={"bg-[#c3e0e0]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"SOCIAL CHAIR"} name={"Bella Qiu"} bgColor={"bg-[#c3e0e0]"}/>
              <Alumni title={"SOCIAL VICE CHAIR"} name={"Audrey Liang"} bgColor={"bg-[#c3e0e0]"}/>
              <Alumni title={"SOCIAL VICE CHAIR"} name={"Ellen Zuo"} bgColor={"bg-[#c3e0e0]"}/>
            </div>
            <div className='flex flex-col gap-8'>
              <Alumni title={"PROFESSIONAL DEVELOPMENT CHAIR"} name={"Mark Yang"} bgColor={"bg-[#c3e0e0]"}/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AlumniPage
import React from 'react'
import { AiTwotoneCalendar } from 'react-icons/ai'
import { GrLocation } from 'react-icons/gr'
import event_background from "../../assets/events/event_background.jpg"

const EventFlatCard = () => {
  return (
    <div className='group rounded shadow-lg w-[70vw] h-[28vh] bg-[#ffffff] p-4 flex justify-between hover:shadow-xl duration-300'>
        <div className='h-full w-[16vw] overflow-hidden'>
            <img className="h-full w-full object-cover group-hover:scale-[1.05] duration-300" src={event_background} alt="Sunset in the mountains" />
        </div>
        <div className='w-[50vw]'>
            <div className='text-lg uppercase text-rose-800'>workshop</div>
            <div className="font-bold text-3xl mt-2 mb-3 group-hover:text-cyan-500 hover:cursor-pointer duration-300">Lavender Upcoming Event title #1</div>
            <div className='flex items-center gap-2 text-xl mb-2'>
                <AiTwotoneCalendar color='darkblue' size={20}></AiTwotoneCalendar>
                <p>Wed, Jan 11, 2023</p>
            </div>
            <div className='flex items-center gap-2 text-xl mb-2'>
                <GrLocation color='darkblue' size={20}></GrLocation>
                <p>Social Science 110, Campus</p>
            </div>
        </div>
    </div>
  )
}

export default EventFlatCard
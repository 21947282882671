import { useState } from 'react';
import { Back, Card, CardContainer, Front } from './FlipCard.styled';

export interface FlipCardInfo {
    title: string;
    image: string;
    description: string;
}
const FlipCard = (cardInfo: FlipCardInfo) => {
    const { title, image, description } = cardInfo;

    const [mouseEnter, setMouseEnter] = useState(false);

    const handleMouseEnter = () => {
        setMouseEnter(true);
    };

    const handleMouseLeave = () => {
        setMouseEnter(false);
    };

    return (
        <div
            className='relative w-[22rem] h-[13rem] flex justify-center items-center text-white'
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            <img
                src={image}
                className='absolute h-full w-full object-cover brightness-[0.6] rounded'
                alt=''
            ></img>
            <h1
                className={`text-xl font-bold mt-0 mb-6 absolute duration-300 ${
                    mouseEnter && '-translate-y-14'
                }`}
            >
                {title}
            </h1>
            <p className={`absolute top-[13rem] m-6 opacity-0 duration-500 ${mouseEnter && "-translate-y-40 opacity-100"}`}>
                {description}
            </p>
        </div>
        // {/* flipping version */}
        // <CardContainer>
        //     <Card>
        //         <Front className='rounded'>
        //             <img
        //                 src={image}
        //                 className='absolute h-full w-full object-cover brightness-[0.6] rounded'
        //                 alt=''
        //             ></img>
        //             <h1 className='text-xl font-bold mt-0 mb-6 absolute'>
        //                 {title}
        //             </h1>
        //         </Front>
        //         <Back className='rounded'>
        //             <h1 className='text-xl font-bold mt-0 mb-6'>{title}</h1>
        //             <p className=''>{description}</p>
        //         </Back>
        //     </Card>
        // </CardContainer>
    );
};

export default FlipCard;

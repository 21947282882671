import React from 'react';
import FlipCard from '../../components/FlipCard/FlipCard';
import './Department.css';
import { departmentData } from '../../Data/Department.data';

const Department = () => {
    return (
        <div className='flex flex-col items-center justify-center my-16'>
            <h1 className='text-3xl font-bold mt-0 mb-6'>Departments</h1>
            <p className='mb-8 text-gray-500 w-[75%] text-center'>
                Lavender Alliance has eight different departments, with four
                external departments dedicated to Marketing, Outreach, Publicity, and
                Professional Development, and four internal departments focused on
                Operations, Programming, Social, and Finances.
            </p>
            <div className='w-full flex flex-wrap justify-center gap-6'>
                {departmentData.map((data) => (
                    <FlipCard
                        key={`department-${data.title}`}
                        title={data.title}
                        image={data.image}
                        description={data.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default Department;

import background from '../assets/department/department-background.jpg';

export const departmentData = [
    {
        title: 'Social',
        image: background,
        description:
            'Organize internal events to create bondings between LAB members; Establish a safe space for open discussion',
    },
    {
        title: 'Operations',
        image: background,
        description:
            'Manage meeting minutes and logistics; Organize membership database',
    },
    {
        title: 'Marketing',
        image: background,
        description:
            'Create advertising graphics to promote LAB’s internal and external events; Develop marketing materials for fundraising events',
    },
    {
        title: 'Professional Development',
        image: background,
        description:
            'Build and maintain LAB’s website; Establish career and scholarship opportunities for LGBTQ+ students; Build alumni connections',
    },
    {
        title: 'Publicity',
        image: background,
        description:
            'Publish LGBT-related articles to spread awareness of Asian LGBTQ+ communities; Advocate for social change; Publish monthly newsletters on LGBTQ+ news',
    },
    {
        title: 'Programming',
        image: background,
        description:
            'Organize external events to raise awareness of Asian LGBTQ+ communities and celebrate LGBTQ+ identities',
    },
    {
        title: 'Outreach',
        image: background,
        description:
            'Establish connections with Chinese LGBTQ+ organizations and AAPI LGBTQ+ organizations; Seek collaboration opportunities',
    },
    {
        title: 'Finance',
        image: background,
        description:
            'Organize fundraising events to support LAB and local queer communities',
    },
];

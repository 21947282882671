import styled from 'styled-components';
import tw, { TwStyle } from 'twin.macro';

const SocialMediaButtonHoverColor: { [color: string]: TwStyle } = {
    blue: tw`hover:text-blue-700`,
    purple: tw`hover:text-purple-700`,
    green: tw`hover:text-green-700`,
    default: tw`hover:text-black`,
};

export const SocialMediaButton = styled.button`
    ${({ hoverColor }: { hoverColor: string }) =>
        SocialMediaButtonHoverColor[hoverColor]};

    &:not(:last-child) {
        ${tw`mr-6`};
    }
    transition: 0.2s;
`;

export const SocialMediaButtonsContainer = styled.div`
    ${tw`flex items-center md:cursor-pointer h-full z-50 md:w-auto text-xl`};
`;

export const NavLogoContainer = styled.div`
    ${tw`flex justify-between p-2 sm:pr-8 h-full z-50 w-full md:w-auto items-center`};
`;

export const NavBarContainer = styled.div`
    ${tw`flex items-center font-medium h-full justify-between sm:pl-16 md:pr-16`}
`;

export const NavBarItemsContainer = styled.ul`
    ${tw`md:flex uppercase hidden items-center md:gap-16 text-lg`}
`;

export const MobileNavBarItem = styled.span`
    ${tw`py-7 px-3 inline-block`}
`;

export const MobileNavBarItemsContainer = styled.ul`
    ${tw`md:hidden bg-white absolute w-full h-full bottom-0 py-24 pl-4 text-xl
                      duration-500`}
`;

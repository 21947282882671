import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';

import HomePage from './pages/HomePage';
import BlogsPage from './pages/BlogsPage';
import EventsPage from './pages/EventsPage';
import DepartmentPage from './pages/DepartmentPage';
import AlumniPage from './pages/AlumniPage';
import NavigationStructure from './components/Navigation Structure/NavigationStructure';
import JoinUs from './pages/JoinUs.page';

const App = () => (
    <div className='font-serif'>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<NavigationStructure />}>
                    <Route index element={<HomePage />} />
                    <Route path='/blogs' element={<BlogsPage />} />
                    <Route path='/events' element={<EventsPage />} />
                    <Route path='/department' element={<DepartmentPage />} />
                    <Route path='/alumni' element={<AlumniPage />} />
                    <Route path='/join-us' element={<JoinUs />} />
                </Route>
                <Route path='/testing' element={<Outlet />}>
                    <Route path='/testing/join-us' element={<JoinUs />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
);

export default App;

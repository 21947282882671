import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../sections/Footer/Footer'
import EventFlatCard from '../components/EventFlatCard/EventFlatCard'
import banner_background from "../assets/blogsPage/banner_background.jpg"

const EventsPage = () => {
  return (
    <div>
      <div className='w-full h-[20rem] relative'>
        <img className="h-full w-full object-cover brightness-[0.7] absolute" src={banner_background} alt="banner background" />
        <div className='absolute bottom-[30%] left-[10%] text-white'>
          <h1 className='text-4xl font-semibold'>Featured Events</h1>
          <h3 className='text-2xl pt-3 font-medium'>Home/Events</h3>
        </div>
      </div>
      <div className='h-auto w-full flex items-center flex-col justify-center gap-10 pt-16 pb-16 bg-[#f4f4f4]'>
        <div className='flex gap-4 text-2xl font-bold font-sans'>
            <span className='text-[#6a6966]'>PAST</span>
            <span>/</span>
            <span className='text-[#2e2d29]'>UPCOMING</span>
        </div>
        <EventFlatCard></EventFlatCard>
        <EventFlatCard></EventFlatCard>
        <EventFlatCard></EventFlatCard>
      </div>
    </div>
  )
}

export default EventsPage
